import styled from '@emotion/styled';
import { theme } from '../../../styles';

export const HeadingContainerStyled = styled.div`
  margin-top: 40px;

  &.NoMarginBottom {
    .HeadingContainer__TitleSection {
      margin-bottom: 0;

      @media (min-width: ${theme.breakpoints.md}) {
        margin-bottom: 65px;
      }
    }

    .HeadingContainer__Title {
      @media (min-width: ${theme.breakpoints.md}) {
        margin-bottom: 30px;
      }
    }
  }

  .HeadingContainer__Section-BreakLine {
    border-top: 3px solid black;
    margin-bottom: 50px;

    @media (min-width: ${theme.breakpoints.l}) {
      margin-bottom: ${theme.scale('70px')};
    }
  }

  .HeadingContainer__Title {
    text-transform: uppercase;
    color: black;
    margin-bottom: 40px;

    @media (min-width: ${theme.breakpoints.md}) {
      margin-bottom: ${theme.scale('80px')};
      width: 30%;
      min-width: 300px;
      margin-bottom: 20px;
    }

    @media (min-width: ${theme.breakpoints.l}) {
      min-width: 400px;
      margin-bottom: 80px;
    }
  }

  .HeadingContainer__TitleSection {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 65px;
    align-items: baseline;

    @media (min-width: ${theme.breakpoints.md}) {
      flex-direction: row;
      align-items: flex-start;
      margin-bottom: ${theme.scale('90px')};
    }
  }

  .HeadingContainer__TitleBody {
    color: black;
    text-align: left;
    margin-bottom: 0;

    h3 {
      line-height: 1.4;
    }
  }

  .Link {
    text-decoration: none;
    text-transform: uppercase;
    color: inherit;
    font-weight: 600;
    font-size: 0.75rem;
    border: 1px solid currentColor;
    padding: 0.8em 1.6em;
    display: inline-flex;
    align-items: center;
    width: fit-content;

    &:hover {
      border-color: ${theme.colors.primaryred};
      color: ${theme.colors.primaryred};

      svg {
        transform: translateX(4px);
      }
    }

    span {
      vertical-align: middle;
      line-height: 1;
      padding-top: 0.2em;
    }

    svg {
      width: auto;
      height: 1.2em;
      color: inherit;
      margin-left: 0.8em;
      transition: transform 0.3s ease;
    }
  }
`;

export const HeadingContent = styled.div`
  position: relative;

  img {
    max-width: 150px;
    min-height: 68px;
  }
`;

export const HeadingDownloadIconContainer = styled.a`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid ${theme.colors.black};
  padding: 4px;

  span {
    max-width: 80px;
    text-align: center;
    line-height: 1.2;
    padding-top: 0.4em;
    font-weight: 300;
    font-size: 0.875rem;
  }

  img {
    object-fit: contain;
    height: 26px;
    width: auto;
    min-height: 26px;
  }

  &:hover {
    border-color: ${theme.colors.primaryred};
    color: ${theme.colors.primaryred};
  }

`;
