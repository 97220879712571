import React from 'react';

export const ArrowRight = ({ className }) => {
  return (
    <svg 
      height="800px" 
      width="800px" 
      xmlns="http://www.w3.org/2000/svg" 
      xmlnsXlink="http://www.w3.org/1999/xlink" 
      viewBox="0 0 227.096 227.096" 
      xmlSpace="preserve"
      className={className}
    >
      <g>
        <g>
          <polygon fill="currentColor" points="152.835,39.285 146.933,45.183 211.113,109.373 0,109.373 0,117.723 211.124,117.723 146.933,181.902 152.835,187.811 227.096,113.55" strokeWidth="4" stroke="currentColor"/>
        </g>
      </g>
    </svg>
  )
}