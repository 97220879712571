import React, { useRef, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby'; // Ensure this import is present
import Title from '../../Title/Title';
import Text from '../../Text/Text';
import {
  HeadingContainerStyled,
  HeadingContent,
} from './HeadingContainer.css';
import Fade from 'react-reveal/Fade';
import { useTextAnimationSecondary } from '../../../hooks/useTextAnimation';
import IntersectionVisible from 'react-intersection-visible';
import { ArrowRight } from '../../Icons/ArrowRight';
import { DownloadPDF } from '../../DownloadPDF/DownloadPDF';
import LocaleContext from '../../../context/LocaleProvider';

const translations = {
  en: {
    button: 'Read more'
  },
  fr: {
    button: 'Lire plus'
  }
};

const HeadingContainer = ({
  hideBorder,
  className,
  isHTML = false,
  withRedPoint,
  noAnimation,
  image,
  image_link,
  title,
  title_link,
  body,
  link_to_pdf,
  block_link,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const titleRef = useRef(null);
  const titleProps = {
    type: 'h3',
    as: 'h3',
    customClassName: 'HeadingContainer__Title',
    withRedPoint: withRedPoint === false ? false : true,
  };
  useTextAnimationSecondary({ element: titleRef }, [isVisible]);

  // Prevent event propagation for specific links
  const handleLinkClick = (e) => {
    e.stopPropagation();
  };

  const lang = useContext(LocaleContext);
  const i18n = lang?.i18n[lang?.locale]?.path || 'en';

  const buttonLabel = translations[i18n].button;

  const content = (
    <Fade bottom distance='30px'>
      <HeadingContainerStyled
        className={`${className} ${body ? '' : 'NoMarginBottom'}`}
      >
        {hideBorder ? (
          ''
        ) : (
          <hr className='HeadingContainer__Section-BreakLine' />
        )}
        <div className={`HeadingContainer__TitleSection`}>
          {title_link ? (
            <a href={title_link} target='_blank' onClick={handleLinkClick}>
              <Title ref={noAnimation ? null : titleRef} {...titleProps}>
                {title}
              </Title>
            </a>
          ) : (
            <Title ref={noAnimation ? null : titleRef} {...titleProps}>
              {title}
            </Title>
          )}
          <HeadingContent className='HeadingContainer__TitleBody'>
            {link_to_pdf && (
              <DownloadPDF
                link={link_to_pdf}
                onClick={handleLinkClick}
              />
            )}

            {image && (
              <a
                href={image_link || '#'}
                target='_blank'
                onClick={image_link ? handleLinkClick : undefined}
                style={{ marginBottom: 20, display: 'block' }}
              >
                <img src={image} alt='' />
              </a>
            )}
            {isHTML ? (
              <Text
                as='div'
                type='secondary'
                customClassName='HeadingContainer__TitleBody'
                dangerouslySetInnerHTML={{ __html: body }}
              />
            ) : (
              body && (
                <Text
                  as='div'
                  type='secondary'
                  customClassName='HeadingContainer__TitleBody'
                >
                  {body}
                </Text>
              )
            )}
            {block_link && (
              <Link to={block_link} onClick={handleLinkClick} className='Link'>
                <span>{buttonLabel}</span>
                <ArrowRight className='arrow-right' />
              </Link>
            )}
          </HeadingContent>
        </div>
      </HeadingContainerStyled>
    </Fade>
  );

  return (
    <IntersectionVisible onShow={() => setIsVisible(true)}>
      {block_link ? (
        <div
          // to={block_link}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          {content}
        </div>
      ) : (
        content
      )}
    </IntersectionVisible>
  );
};

HeadingContainer.propTypes = {
  hideBorder: PropTypes.bool,
  className: PropTypes.string,
  isHTML: PropTypes.bool,
  withRedPoint: PropTypes.bool,
  noAnimation: PropTypes.bool,
  image: PropTypes.string,
  image_link: PropTypes.string,
  title: PropTypes.string,
  title_link: PropTypes.string,
  body: PropTypes.string,
  link_to_pdf: PropTypes.string,
  block_link: PropTypes.string,
};

export default HeadingContainer;
